<template>
  <div>
    <Modal :options="{width: '40vw',type:'warning'}">
      <div class="title">Descarga masiva</div>
      <div class="body">
        <div class="row">
          <div class="col text-center">Se generará un proceso para descargar {{ facturas.length }} facturas seleccionadas, <br/>una vez finalizado se enviarán por correo.</div>
        </div>
        <div class="row mt-3">
          <div class="col text-center">¿Deseas continuar?</div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-primary mr-2" @click="descargar_facturas">Descargar</button>
            <button class="btn btn-danger" @click="$emit('cancelar')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/tesoreria/api/tesoreria';

export default {
  components: {
    Modal
  },
  props: {
    facturas: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    async descargar_facturas() {
      let ids = [];

      this.facturas.forEach(factura => {
        ids.push(factura.id);
      })

      let res = (await api.descarga_masiva(ids)).data;
      this.$log.info('res', res);

      this.$emit('cancelar');
    }
  }
}
</script>